import React, {Component} from 'react';
import {Row, Container, Image} from "react-bootstrap";
import Toolbar from "./Toolbar";
import '../../Constants.jsx';
import * as firebase from 'firebase';
import './Products.css';

class Products extends Component {
    constructor(props){
        super(props);
        this.state = {
            currOption:" ",
            bool:true,
            fileURL:[],
            head: " ",
            desc:" ",
            recipe: " ",
            img1: " ",
            img2: " ",
            label: []

        };
        this.chooseOption = this.chooseOption.bind(this)
    }
    chooseOption(selectedOption) {
        this.setState({
            currOption:selectedOption,
            fileURL: [],
            label: [],
            bool:true
        });
    }

    descriptionUpdater = (Title, Description, Ingredients, Image1, Image2) => {
        this.setState({
            head: Title ,
            desc: Description,
            recipe: Ingredients,
            img1: Image1,
            img2: Image2
        });
    };

    componentDidUpdate() {
        if (this.state.bool) {
            // Get a reference to the storage service, which is used to create references in your storage bucket
            let storage = firebase.storage();
            let storageRef = storage.ref();
            // Create a reference under which you want to list
            let listRef = storageRef.child(this.state.currOption);

            // Find all the prefixes and items.
            listRef.listAll().then((res) => {
                res.items.forEach((itemRef) => {
                    itemRef.getMetadata().then((metadata) => {
                        itemRef.getDownloadURL().then((url) => {
                            let joined = this.state.fileURL.concat(url);
                            let title = this.state.label.concat(metadata.name);
                            this.setState({label: title});
                            this.setState({fileURL: joined});
                        }).catch(function (error) {
                            // Handle any errors
                            console.log(error);
                        });
                    });
                });
            }).catch(function (error) {
                console.log(error);
            });
            this.state.bool = false;
        }
    }
    render() {
        let body;
        let prodImgs;
        let divide;

        if (this.state.desc === undefined && this.state.recipe === undefined) {
            divide = (
                <hr className="Text-break" style={{display: "none"}}/>
            );
        }
        else {
            divide = (<hr className="Text-break" style={{display: "block"}}/>);
        }

        if (this.state.currOption !== " ") {
            body =
                (<div className="Content">
                    <h2 className="Image-title">{this.state.head}</h2>
                    <hr className="Text-break" style={{display: "block"}}/>
                    <p className="Text-content">{this.state.desc}</p>
                    <p className="Text-content">{this.state.recipe}</p>
                    {divide}
                </div>);
        }
        else {
            body=(<div className="Content">
                    <h3 className="Placeholder">Click on product categories above to view details</h3>
                </div>
            )
        }

        if(this.state.img1 !== undefined && this.state.img2 !== undefined) {
            prodImgs = (
                <div className="Image-div">
                    <Image className="Info-img img1" src={this.state.img1}/>
                    <Image className="Info-img img2" src={this.state.img2}/>
                </div>
            )
        }
        else if (this.state.img1 === undefined && this.state.img2 !== undefined) {
            prodImgs = (
                <div className="Image-div">
                    <Image className="Info-img img2" src={this.state.img2}/>
                </div>
            )
        }
        else if (this.state.img1 !== undefined && this.state.img2 === undefined) {
            prodImgs = (
                <div className="Image-div">
                    <Image className="Info-img img1" src={this.state.img1}/>
                </div>
            )
        }
        else {
            prodImgs = (
                <div className="empty">
                </div>
            )
        }
        /*This set of code divides the URLS into rows containing 4 images*/
        let path = this.state.fileURL.length;
        // array of N elements, where N is the number of rows needed
        const rows = [...Array(Math.ceil(path/3))];
        // chunk the products into the array of rows
        const imgRows = rows.map((row, num) => this.state.fileURL.slice(num * 3, num * 3 + 3) );
        let photoNum =  Array.from(Array(path).keys());
        // Map the rows as div.row
        const imgURL = imgRows.map((row, num) => (

            <Row className="Image-container" key={num}>
                {/*map products in the col as images */}
                {row.map((url, index) => (
                    <figure className="Product_item">
                        <Image
                            className="Photo-Images"
                            alt={this.state.label[photoNum[index+(3*num)]].replace(/\.[^/.]+$/, "")}
                            key={photoNum[index+(3*num)]}
                            src={url} />
                            <figcaption className="Image-caption">{this.state.label[photoNum[index+(3*num)]].replace(/\.[^/.]+$/, "")}</figcaption>
                    </figure>
                ))}
            </Row>
        ));

        return (
            <div className="Products" id="Products">
                <Container>
                    <h1>Products</h1>
                    <Toolbar
                        currOption={this.chooseOption}
                        description={this.descriptionUpdater}/>
                    {body}
                    <div className="Images">
                        {imgURL}
                    </div>
                    {prodImgs}
                </Container>
            </div>
        );
    }
}

export default Products;
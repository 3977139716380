import React, {Component} from 'react';
import {Image, Nav, Navbar, NavItem} from "react-bootstrap";
import './CustomNavbar.css';
import {Link} from 'react-scroll';

class CustomNavbar extends Component {
    render()
    {
        return(
            <Navbar collapseOnSelect expand="lg" variant="light" fixed="top" className="Navbar">
                <Navbar.Brand href="/" className="Logo-container"><Image src="/assets/EFT_Logo.png" className="Logo"/></Navbar.Brand>
                <div className="spacer"/>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <li className="Navbar-nav-items"><NavItem className="Navbar-links"><Link
                            activeClass="active"
                            to="Home"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration= {500}
                            className
                        >Home</Link></NavItem></li>
                        <li className="Navbar-nav-items"><NavItem className="Navbar-links">
                            <Link
                                activeClass="active"
                                to="About"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration= {500}
                            >About</Link></NavItem></li>
                        <li className="Navbar-nav-items"><NavItem className="Navbar-links">
                            <Link
                                activeClass="active"
                                to="Products"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration= {500}
                            >Products</Link></NavItem></li>
                        <li className="Navbar-nav-items"><NavItem className="Navbar-links">
                            <Link
                                activeClass="active"
                                to="Contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration= {500}
                            >Contact</Link></NavItem></li>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default CustomNavbar;
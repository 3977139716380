import React, {Component} from 'react';
import {Container, Carousel} from 'react-bootstrap';
import './Home.css';

class Home extends Component {

    render() {
        return (
            <div className="Home" id="Home">
                <Container className="Home-container">
                    <Carousel className="Home-carousel">
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="assets/Carousel/1.jpg"
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="assets/Carousel/2.jpg"
                                alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="assets/Carousel/3.jpg"
                                alt="Third slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="assets\Carousel\4.jpg"
                                alt="Fourth slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="assets\Carousel\5.jpg"
                                alt="Fifth slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="assets\Carousel\6.jpg"
                                alt="Sixth slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="assets\Carousel\7.jpg"
                                alt="Seventh slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="assets\Carousel\8.jpg"
                                alt="Eight slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                </Container>
            </div>
        );
    }
}

export default Home;